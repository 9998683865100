import React ,{useState} from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, Form, FormGroup, Input,ModalBody,Modal } from 'reactstrap'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
// import { FiArrowRight } from 'react-icons/fi'
import Fade from 'react-reveal/Fade';
import Img1 from '../images/svg/enterprise1.svg'
import Img2 from '../images/svg/enterprise2.svg'
import Img3 from '../images/svg/enterprise3.svg'

import BlogForm from './Company/Blog/BlogForm'



const Enterprise = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
      query {
        mainBanner: file(relativePath: { eq: "enterprise_banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1220, maxHeight:400,) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
       
      }
    `}

            render={data => {
                const {
                    // buttonLabel,
                    className
                } = props;
                const minBanner = data.mainBanner.childImageSharp.fluid
                // const imageData = data.enterpriseImg.childImageSharp.fluid

                return (
                    <Layout path="/enterprise/">
                        <section className="banner_min">
                            <div className="banner_section">
                                <Img
                                    fluid={minBanner}
                                    className="startup_background"
                                ></Img>
                            </div>
                            <div className="banner_blur">
                                <div className="first_banner">
                                    <Container>
                                        <Row>
                                            <Col sm="12" md={{ size: 7, offset: 6 }}>
                                                <Fade right>
                                                    <div>
                                                        <h1>Is your company on a tearing growth curve?</h1>
                                                    </div>
                                                </Fade>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section className="heading_section_bg">
                            <Container>
                                <div className="heading_section">
                                    <p>There are over 700,000 jobs unfilled in the IT sector, as reported in a Linkedin survey.<br /> Most enterprises are spending a majority of their dollars on Keeping the Lights On, and CIOs tasked with transformation are challenged by finding the right talent that would lead these transformative projects.</p>
                                </div>
                            </Container>
                        </section>
                        <section className="startup_section">
                            <Container>
                                <Row>
                                    <Col md="2">
                                        <div className="section_img">
                                            <img src={Img1} alt="img" />
                                        </div>
                                    </Col>
                                    <Col md="10">
                                        <div className="startup_section_text">
                                            <h3>Trident shines where most recruiters give up</h3>
                                            <p>Whether it is finding that rare systems engineer required to keep your legacy lights on, or that DevOps engineer to lead your API initiatives, we have found the right talent at the right time. Every time.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <section>
                            <div className="startup_bg">
                                <div className="startup_section">
                                    <Container>
                                        <Row>
                                            <Col md={{ size: 10, order: 1 }} xs={{ order: 2 }}>
                                                <div className="startup_section_content">
                                                    <h3>No niche too tough</h3>
                                                    <p>The standard open requirements are easy to fill—there are many candidates who can easily fit the bill or train in a few weeks to deliver on the job profile. But when it comes to finding niche talent, it is an art—of building the right conversations, delivering the right vision, and creating the urgency to move into the active zone. Trident delivers on niche requirements—from understanding your project needs accurately and seeking the right candidate who would add value to your project progress.</p>
                                                </div>
                                            </Col>
                                            <Col md={{ size: 2, order: 2 }} xs={{ order: 1 }}>
                                                <div className="section_img1">
                                                    <img src={Img2} alt="img" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section className="startup_section">
                            <Container>
                                <Row>
                                    <Col md="2">
                                        <div className="section_img">
                                            <img src={Img3} alt="img" />
                                        </div>
                                    </Col>
                                    <Col md="10">
                                        <div className="startup_section_content">
                                            <h3>We win by winning trust</h3>
                                            <p>Many large corporations that began with doubts are today listing us as their “go-to” agency for filling tough requirements. We believe that the only way to win your business is to win your trust by delivering to your needs. We also believe that our talent trusts us with their career goals—from finding new roles to diversifying into different projects with newly learnt skills, we have helped many climb the enterprise ladders over the last decade and more. When we work on trust, everyone wins.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <section>
                            <div className="startup_bg">
                                <div className="startup_section">
                                    <Container>
                                        <Row>
                                            <div className="card">
                                                <Col md="12">
                                                    <div>
                                                        <div className="text_heading">
                                                            {/* <h3>This isn't your average referral program.</h3> */}
                                                            <h3>Refer a Client</h3>
                                                            <h3 className="form_heading_text text_color">The easiest way to make a difference.</h3>
                                                            <div className="form_text">
                                                                <p>
                                                                    {/* Proud to Refer® is  */}
                                                                    Trident's client referral program: It’s a way for us to continually serve our existing clients, make new connections, and impact worthy organizations around the world. Refer a business using the form below, and when we match one of our carefully selected associates with the business you referred, we will donate $500 in your name to the charity of your choice!
                                                                {/* Trident's client referral program, it’s a way for us to continually serve our existing clients, make new connections and impact worthy organizations around the world. Refer a business using the form below, and when we match one of our carefully selected associates with the business you referred, we will donate $500 in your name to the charity of your choice! */}
                                                                </p>
                                                                {/* <p>To qualify, an official job order must be filled by Trident for the company you refer.
                                                                Check out check out the program rules. Only select Trident offices are participating in the “Proud to Refer®” Client Referral Program.
                                                            </p> */}
                                                                <br />
                                                                <br />
                                                            </div>
                                                        </div>
                                                        <Form>
                                                            <div>
                                                                <h3 className="form_heading ">STEP 1 - Business Information</h3>
                                                            </div>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="name" placeholder="Contact First Name*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="name" placeholder="Contact Last Name*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="Name" placeholder="Business Name*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="job" placeholder="Job Title*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="email" name="address" placeholder="Contact Email Address*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="number" name="number" placeholder="Contact Phone Number" />
                                                                    </FormGroup>
                                                                </Col>
                                                                {/* <Col md={6}>
                                                                <FormGroup>
                                                                    <CustomInput bsSize="md" type="select" id="exampleCustomSelect">
                                                                        <option value="">Select Participating Trident Office*</option>
                                                                        <option>Value 1</option>
                                                                        <option>Value 2</option>
                                                                        <option>Value 3</option>
                                                                        <option>Value 4</option>
                                                                        <option>Value 5</option>
                                                                    </CustomInput>
                                                                </FormGroup>
                                                            </Col> */}
                                                            </Row>
                                                            <div className="form_text">
                                                                <h3 className="form_heading">STEP 2 - Your Information</h3>
                                                            </div>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="FirstName" placeholder="Your First Name*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="LastName" placeholder="Your Last Name" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="Name" placeholder="Company Name" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="text" name="Job" placeholder="Job Title" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="email" name="Email" placeholder="Your Email Address*" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Input bsSize="md" type="number" name="PhoneNumber" placeholder="Your Phone Number" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            {/* <p className="form_text">Referral program not available in all locations. Check with your local Trident office for details.</p> */}
                                                            <button>Submit</button>
                                                        </Form>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>Try your next talent recruiting with us </h1>
                                        <div className="footer_button">
                                            <a onClick={toggle}href >Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                            <ModalBody >
                                                <BlogForm title="Try your next talent recruiting with us" close={()=> toggle()}/>
                                            </ModalBody>

                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {/* <BlogForm refItem = {()=>{}} title = "Try your next talent recruiting with us." redirectTitle="Enterprise" pathname={props.location.pathname}/> */}

                    </Layout>
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(Enterprise)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection